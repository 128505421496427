export const getProductId = (slug: string | string[] | undefined) => {
  if (!slug || typeof slug === 'object') return undefined
  const matches = slug.match(/(\d+)$/)
  return (matches && matches[0]) || undefined
}

export const getProductsListingSkus = (products: ProductListItem[]) => {
  if (!products || !products.length) return []
  return products
    .map((product) =>
      product.declinations?.map((declination) => declination.sku)
    )
    .flat()
}
