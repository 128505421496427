import { FC } from 'react'
import NextHead from 'next/head'

interface ProductStructuredDataProps {
  product: Product
  productName: string
  price?: number | string
  currency?: string
  availability: boolean
}

const ProductStructuredData: FC<ProductStructuredDataProps> = ({
  product,
  productName,
  price,
  currency,
  availability,
}) => {
  const baseUrl =
    process.env.NEXT_PUBLIC_BASE_URL || 'https://www.smallable.com'

  const siblings = product?.siblings || []
  const description = product?.longDescription?.replace(/<[^>]*>/g, ' ')

  const productJsonLd = {
    '@context': 'https://schema.org',
    '@type': 'Product',
    name: productName,
    image: product.medias[0]?.['thickbox'],
    description: description,
    brand: {
      '@type': 'Brand',
      name: product.brand.name,
    },
    model: product.id ? `${product.id}` : undefined,
    sku: product.declinations[0]?.sku,
    color: product.color,
    logo: `${process.env.NEXT_PUBLIC_ASSETS_URL}/bundles/smallablefront/css/img/logo.png`,
    offers: {
      '@type': 'Offer',
      url: product.canonicalUrl,
      priceCurrency: currency,
      price: price ? `${price}` : undefined,
      availability: availability
        ? 'https://schema.org/InStock'
        : 'https://schema.org/SoldOut',
      seller: {
        '@type': 'Organization',
        name: 'Smallable',
      },
    },
  }

  const jsonLd = siblings?.length
    ? [
        {
          '@context': 'https://schema.org',
          '@type': 'ProductGroup',
          '@id': product.name,
          name: product.name,
          description: description,
          brand: {
            '@type': 'Brand',
            name: product.brand.name,
          },
          variesBy: 'https://schema.org/color',
          hasVariant: [
            productJsonLd,
            ...siblings.map((sibling) => ({
              url: `${baseUrl}/product/${sibling.id}`,
            })),
          ],
        },
      ]
    : productJsonLd

  if (!jsonLd) return null

  return (
    <NextHead>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
      />
    </NextHead>
  )
}

export default ProductStructuredData
