import { FC, useEffect, useState } from 'react'
import NextHead from 'next/head'
import { useUserSettings } from '@context/session'
import { getProductsListingSkus } from '@utils/products'
import { getMinAndMaxProductsListingPrices } from '@utils/prices'
import { getReviewsSummaryInfos } from '@utils/trustpilot'
import { ENABLE_TRUSTPILOT } from '@constants'

interface JsonLd {
  '@context': string
  '@type': string
  name: string
  description: string
  offers: {
    '@type': string
    offerCount: number
    lowPrice: number
    highPrice: number
    priceCurrency: string
    seller: {
      '@type': string
      name: string
    }
  }
  aggregateRating?: {
    '@type': string
    ratingValue: number
    reviewCount: number
    bestRating: number
    worstRating: number
  }
}
interface ReviewsRating {
  average: number
  totalOfRates: number
  minRating: number
  maxRating: number
}

interface ProductsListingStructuredDataProps {
  pageTitle: string
  pageDescription: string
  blockListing?: PageBlockDataTypes
}

const ProductsListingStructuredData: FC<ProductsListingStructuredDataProps> = ({
  pageTitle,
  pageDescription,
  blockListing,
}) => {
  const enableTrustpilot = ENABLE_TRUSTPILOT
  const { currencyCode } = useUserSettings()

  const products: ProductListItem[] =
    'products' in blockListing ? blockListing?.products : []
  const { minPrice, maxPrice } = getMinAndMaxProductsListingPrices(products)

  const [reviewsRating, setReviewsRating] = useState<ReviewsRating | null>(null)

  useEffect(() => {
    if (!enableTrustpilot) {
      return
    }

    const fetchReviews = async () => {
      try {
        const reviews = await getReviewsSummaryInfos({
          skus: getProductsListingSkus(products),
        })
        setReviewsRating(reviews)
      } catch (error) {
        console.log('error', error)
      }
    }

    fetchReviews()
  }, [products, enableTrustpilot])

  const jsonLd: JsonLd = {
    '@context': 'https://schema.org',
    '@type': 'Product',
    name: pageTitle,
    description: pageDescription,
    offers: {
      '@type': 'AggregateOffer',
      offerCount: products?.length,
      lowPrice: minPrice,
      highPrice: maxPrice,
      priceCurrency: currencyCode,
      seller: {
        '@type': 'Organization',
        name: 'Smallable',
      },
    },
  }

  if (reviewsRating?.totalOfRates > 0) {
    jsonLd.aggregateRating = {
      '@type': 'AggregateRating',
      ratingValue: reviewsRating.average,
      reviewCount: reviewsRating.totalOfRates,
      bestRating: reviewsRating.maxRating,
      worstRating: reviewsRating.minRating,
    }
  }

  if (!jsonLd) return null

  return (
    <NextHead>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
      />
    </NextHead>
  )
}

export default ProductsListingStructuredData
