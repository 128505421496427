import { fetchProductsListingReviewsSummary } from '@smallable/app-catalog/mw/reviews'

interface NumberOfStars {
  oneStar: number
  twoStars: number
  threeStars: number
  fourStars: number
  fiveStars: number
}

enum RatingKeys {
  oneStar = 1,
  twoStars = 2,
  threeStars = 3,
  fourStars = 4,
  fiveStars = 5,
}

const getMinMaxRating = (numberOfStars: NumberOfStars) => {
  const ratings = Object.entries(numberOfStars)
    .filter(([_, value]) => value > 0)
    .map(([key]) => RatingKeys[key])

  if (!ratings.length) return { minRating: 0, maxRating: 5 }

  return { minRating: Math.min(...ratings), maxRating: Math.max(...ratings) }
}

export const getReviewsSummaryInfos = async ({ skus }: { skus: string[] }) => {
  try {
    const res = await fetchProductsListingReviewsSummary({ skus })
    if (!res || !res.numberOfReviews?.total) return null
    const {
      numberOfReviews: { total, ...numberOfStars },
      starsAverage,
    } = res

    const { minRating, maxRating } = getMinMaxRating(numberOfStars)
    return { minRating, maxRating, average: starsAverage, totalOfRates: total }
  } catch (error) {
    console.log('Error while fetching listing reviews', error)
  }
}
