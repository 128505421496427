import { FC } from 'react'
import NextHead from 'next/head'
import { useRouter } from 'next/router'

import { BreadCrumbItem } from '@components/product/BreadCrumb/BreadCrumb'

interface StructuredDataProps {
  breadCrumbItems: Array<BreadCrumbItem>
  productName?: string
}

const ProductStructuredData: FC<StructuredDataProps> = ({
  breadCrumbItems,
  productName,
}) => {
  const baseUrl =
    process.env.NEXT_PUBLIC_SMALLABLE_BASE_URL || 'https://www.smallable.com'
  const { asPath, locale } = useRouter()

  const JsonLd = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      // first item is always the home page
      {
        '@type': 'ListItem',
        position: 1,
        item: `${baseUrl}/${locale}`,
        name: 'Home',
      },
      // then the rest of the breadcrumb items (categories, subcategories, etc.)
      ...breadCrumbItems.map((item, index) => ({
        '@type': 'ListItem',
        position: index + 2,
        item: baseUrl + item.href,
        name: item.title.replace('/', '').trim(),
      })),
      // last item is the product page
      {
        '@type': 'ListItem',
        position: breadCrumbItems.length + 2,
        item: baseUrl + asPath,
        name: productName,
      },
    ],
  }

  if (!JsonLd) return null

  return (
    <NextHead>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(JsonLd) }}
      />
    </NextHead>
  )
}

export default ProductStructuredData
