import { FC } from 'react'
import NextHead from 'next/head'

interface JsonLd {
  '@context': string
  '@type': string
  name: string
  description: string
  aggregateRating: {
    '@type': string
    ratingValue: number
    reviewCount: number
  }
}

interface ReviewsStructuredDataProps {
  ratingData: ReviewRating
  metaDescription: string
}

const ReviewsStructuredData: FC<ReviewsStructuredDataProps> = ({
  ratingData,
  metaDescription,
}) => {
  const jsonLd: JsonLd = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: 'Smallable ',
    description: metaDescription,
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: ratingData?.score?.stars,
      reviewCount: ratingData?.numberOfReviews?.total,
    },
  }

  if (!jsonLd) return null

  return (
    <NextHead>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
      />
    </NextHead>
  )
}

export default ReviewsStructuredData
